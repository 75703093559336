@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

.brand_item {
  @apply border-2 w-20 h-20 rounded-full m-auto flex justify-center items-center group-hover:border-yellow-400;
}

.footer_link {
  @apply border-2 w-12 h-12 rounded-full m-auto flex justify-center items-center hover:bg-blue-50 hover:bg-opacity-20 cursor-pointer;
}

.flow-content > * + * {
  margin-top: 20px;
}
